.scatter-chart-rop {
  text {
    font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana,
      sans-serif;
    font-size: 15px;
    color: rgb(118, 118, 118);
  }
}

.popup-click-rop {
  max-height: 340px;
  padding: 0 10px;
  .item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .product-image {
      width: 80px;
      height: 80px;
      border: 1px solid #cbc1c1;
      border-radius: 5px;
    }
    .product-info {
      padding-left: 10px;
      width: calc(100% - 80px);
    }
  }
  .item-content-border {
    border-top: 1px solid #e8e8e8;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .value-item {
    font-style: italic;
  }
  .stock-and-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .line-height {
    line-height: 1.6;
  }
}

.btn-small-size-popup {
  .dx-button-content {
    padding: 5px 10px !important;
  }
}
