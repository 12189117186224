.form-search-alarm {
  .dx-form-group-content {
    padding-top: 10px !important;
    padding-bottom: 5px !important;
  }
  .form-label-custom {
    .dx-field-item-label {
      margin-bottom: 8px !important;
      font-weight: bold;
    }
  }
}
.popover-notification {
  .title-page {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    color: #1d2939;
    margin: 5px 0;
  }
}
.alarm-notice-list {
  .dx-list-item.dx-state-focused,
  .dx-list-item.dx-state-active {
    background-color: rgba(0,0,0,.04) !important;
    color: #333 !important
  }
}
